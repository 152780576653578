import React, { useState } from "react";
import { Grid, Hidden } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BodyText from "library/text/body/BodyText";
import Title from "library/text/titles/Title";
import { getLogosGeneric } from "utils/resolveLogos";
import PrimaryButton from "library/buttons/PrimaryButton";
import SignupForm from "./components/SignUpForm";
import LoginForm from "./components/LoginForm";
import GraphicPanel from "./components/GraphicPanel";

const styles = {
  root: {
    flexGrow: 1,
    minHeight: "100vh",
  },
  column: {
    height: "100%",
  },
  leftItems: {
    maxWidth: "375px",
    paddingTop: "15%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  leftGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    height: "60px",
    marginBottom: "60px",
  },
};

export default function LogInSignUpPage() {
  const [signUpSuccessful, setSignUpSuccessful] = useState(false);
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const navigate = useNavigate();

  const pageContents = {
    signin: {
      title: "Sign in",
      content: <LoginForm />,
    },
    signup: {
      title: "Sign up",
      header: "Enter your details to register for an account.",
      content: (
        <SignupForm
          setSignUpSuccessful={setSignUpSuccessful}
          setEmail={setEmail}
          setFirstName={setFirstName}
        />
      ),
    },
    signupSuccess: {
      title: "Thanks for signing up!",
      header: "",
      content: (
        <div>
          <BodyText key="body0" style={{ marginBottom: "20px" }}>
            Thank you for signing up with FrontRunnr {firstName}!
          </BodyText>
          <BodyText key="body1" style={{ marginBottom: "20px" }}>
            Your account for {email} will be confirmed shortly. If you cannot log in after one day or if you have any questions, please contact an admin.
          </BodyText>
          <PrimaryButton
            sx={{ marginTop: "24px", width: "200px" }}
            onClick={() => navigate("/login")}
          >
            Back
          </PrimaryButton>
        </div>
      ),
    },
  };

  const location = useLocation();
  const page =
    location.pathname === "/login"
      ? "signin"
      : `signup${signUpSuccessful ? "Success" : ""}`;

  return (
    <Grid container sx={styles.root}>
      <Grid item xs={12} md={5} sx={styles.leftGrid}>
        <div style={{ ...styles.column, ...styles.leftItems }}>
          <img
            style={styles.logo}
            src={getLogosGeneric("outpointLogo")}
            alt="OutPoint Logo"
          />
          <Title
            sx={{ marginBottom: "20px" }}
            text={pageContents[page].title}
          />
          <BodyText sx={{ paddingBottom: "15px" }} color="secondary">
            {pageContents[page].header}
          </BodyText>
          <div>{pageContents[page].content}</div>
        </div>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={false} md={7}>
          <div style={styles.column}>
            <GraphicPanel graphicVersion={page} />
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
}
