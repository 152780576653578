import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid, Checkbox } from "@mui/material";
import BodyText from "library/text/body/BodyText";
import PrimaryButton from "library/buttons/PrimaryButton";
import Hyperlink from "library/text/body/Hyperlink";
import { authenticateUser } from "utils/authentication";
import TextFieldSimple from "library/form/OutPointTextField";
import { BG_RED, RED, THEME } from "assets/palette";
import { ErrorOutline } from "@mui/icons-material";
import OutPointCard from "library/surface/OutPointCard";

const styles = {
  form: {
    paddingRight: "5%",
  },
  fullTextBox: {
    width: "100%",
  },
  halfTextBox: {
    width: "45%",
  },
  formField: {
    paddingTop: "3px",
    marginBottom: "15px",
  },
  textBox: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  primary: {
    color: THEME.primary.main,
  },
  secondary: {
    color: THEME.secondary.main,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  hyperlink: {
    color: THEME.primary.main,
  },
  signUpText: {
    marginBottom: "20px",
  },
};

function WarningCard() {
  return (
    <OutPointCard
      sx={{
        backgroundColor: BG_RED,
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        marginBottom: "10px",
      }}
    >
      <ErrorOutline sx={{ fontSize: "20px", color: RED, marginRight: "5px" }} />
      <BodyText>
        The email or password you entered is incorrect. Try again or contact us.
      </BodyText>
    </OutPointCard>
  );
}

function LoginForm() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [checkBoxIsChecked, setCheckBoxIsChecked] = useState(false);
  const [authError, setAuthError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputFields = [
    {
      name: "Email",
      onChange: setEmail,
      sx: styles.fullTextBox,
      type: "text",
      "data-cy": "email",
    },
    {
      name: "Password",
      onChange: setPassword,
      sx: styles.fullTextBox,
      type: "password",
      "data-cy": "password",
    },
  ];

  const onSubmit = (event) => {
    event.preventDefault();
    authenticateUser(email, password, dispatch)
      .then((_) => {
        navigate("/");
      })
      .catch((_) => {
        setAuthError(true);
      });
  };

  return (
    <form style={styles.form} onSubmit={onSubmit}>
      <Grid>
        {authError && <WarningCard />}
        <BodyText color="secondary" sx={styles.signUpText}>
          Need a Frontrunnr account?{" "}
          <Hyperlink sx={styles.hyperlink} where="/signup">
            Sign up
          </Hyperlink>
        </BodyText>
        {inputFields.map((fields) => {
          return <TextFieldSimple key={fields.name} {...fields} disabled={false} />;
        })}
        <div>
          <Checkbox
            checked={checkBoxIsChecked}
            onChange={() =>
              checkBoxIsChecked
                ? setCheckBoxIsChecked(false)
                : setCheckBoxIsChecked(true)
            }
            name="checkbox"
            color="primary"
          />
          <span style={styles.secondary}> Remember me </span>
        </div>
        <PrimaryButton
          type="submit"
          sx={{ marginTop: "15px", marginBottom: "25px" }}
          size="large"
          data-testid="Sign in"
          disabled={
            !(email && email.length > 0 && password && password.length > 0)
          }
          data-cy="login-button"
        >
          Sign In
        </PrimaryButton>
      </Grid>
    </form>
  );
}

export default LoginForm;
